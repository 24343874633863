@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
  font-family: "Satoshi-Black";
  font-style: normal;
  font-weight: 900;
  src: url("static/fonts/Satoshi-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/Satoshi-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/Satoshi-Medium.woff") format("woff");
}

.screen{
  margin-top: 20px;
}
.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

.owners-verify{
  top: 5px !important;
  left: 120px !important;
}

.profile-img {
  position: absolute !important;
  bottom: -50px;
  left: 15vw;
  border: 5px solid white;
}

.chakra-tabs {
  border-color: var(--chakra-colors-chakra-border-color);
  font-family: var(--font-family-satoshi-medium);
}

/* CUSTOM CSS for Quiz Buzzfee package */
.rbq_result_inner_container {
  color: black !important;
}

.rbq_result_inner_description {
  font-size: 14px !important;
}

@media (min-width: 40rem){
  .rbq_result_inner_image {
    height: 100%;
    max-width: 100%;
    min-height: 520px;
  }
}

.prompt-menu-button:hover .prompt-menu-icon {
  color: rgba(62,72,218);
}
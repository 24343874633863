:root { 
  --black: #000000;
  --eaeaf9: #eaeaf9;
  --f2f3f6: #f2f3f6;
  --ffffff: #ffffff;
  --grey: #afaeb9;
  --light: #f0f0f2;
  --mystic: #e2e2ec;
  --primary: #4434be;
  --secondary: #36334e;
  --x060713: #060713;
  --x252fc1: #252fc1;
  --x3f4358: #3f4358;
  --x79809e: #79809e;
 
  --font-size-l: 16px;
  --font-size-m: 15px;
  --font-size-s: 14px;
  --font-size-xl: 20px;
  --font-size-xs: 12px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 48px;
 
  --font-family-inter-bold: "Inter-Bold", Helvetica;
  --font-family-inter-regular: "Inter-Regular", Helvetica;
  --font-family-inter-semibold: "Inter-SemiBold", Helvetica;
  --font-family-satoshi-black: "Satoshi-Black", Helvetica;
  --font-family-satoshi-bold: "Satoshi-Bold", Helvetica;
  --font-family-satoshi-medium: "Satoshi-Medium", Helvetica;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
